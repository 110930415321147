import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import QuestionnaireForm from "src/components/lib/questionnaire-section/QuestionnaireForm";
import { useAppSelector } from "src/hooks/redux-hooks";

export interface QuestionnairePageProps {
  scrollToNextPage: () => void;
}

export const QuestionnairePage: React.FC<QuestionnairePageProps> = ({
  scrollToNextPage,
}) => {
  const { checkId } = useParams<{ checkId: string }>();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue =
        "Are you sure you want to leave? You will need to resubmit the questionnaire.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const profile = useAppSelector((state) => state.profileData);

  return (
    <div className="flex items-center justify-center md:pt-2 pt-4 h-full ">
      <div className="w-full flex justify-center  bg-white py-8 rounded-xl  h-full  overflow-auto">
        <div className="lg:w-2/4 w-full">
          {profile ? (
            <QuestionnaireForm
              checkId={checkId!}
              scrollToNextPage={scrollToNextPage}
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};
