export const vevoTermsAndConditions: Term = {
  assertion: "",
  contents: `<h1>VEVO Terms and Conditions</h1><div>In order for Fragomen to confirm your visa status and your right to work in Australia, we will need to conduct an enquiry through the Department of Home Affairs’ Visa Entitlement Verification Online (VEVO) service. VEVO is an online facility that allows us to check your visa entitlements and right to work.

The terms and conditions of use of VEVO require that your consent is obtained prior to collecting information about you. The VEVO inquiry will be done initially and then on a regular basis (as required) during your employment to ensure you continue to maintain a valid visa and right to work.

By conducting a VEVO inquiry, we will obtain certain information about you, namely your name, date of birth, passport details and visa status. The result of the VEVO enquiry will be shared with your employer.

We will not pass that information onto anyone without your consent. However, if you are not entitled to be in Australia, the Commonwealth may use Fragomen’s VEVO account details to locate you.

By proceeding, you consent to Fragomen conducting a VEVO enquiry now and on a regular basis during your employment and forwarding the results to your employer.

You can withdraw this consent at any time by notifying us in writing.</div>`,
};
