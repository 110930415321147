import { forwardRef, useState, useCallback } from "react";
import { ComponentProps } from "react";
import { cn } from "../../../utils/tailwindMerger";
import {} from "../../index";
import { useAppSelector } from "src/hooks/redux-hooks";
import { WreeFooter, WreeHeader } from "@simplecitizen/gdl-react-ui-components";

type PageWrapperProps = ComponentProps<"div"> & {
  className?: string;
  isLoading?: boolean;
};

export const PageWrapper = forwardRef<HTMLDivElement, PageWrapperProps>(
  ({ className, children }, ref) => {
    /////// show box shadow on scroll down
    const [scroll, setScroll] = useState(false);

    const handleScroll = useCallback(
      (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (e.currentTarget.scrollTop > 100) {
          setScroll(true);
        } else {
          setScroll(false);
        }
      },
      []
    );
    const page_no = useAppSelector(
      (state: any) => state?.page_number.page || ""
    );

    const logo = useAppSelector(
      (state: any) =>
        state?.questionnaire_data?.questionnaire?.client?.branding?.logo || ""
    );
    const client_name: string = useAppSelector(
      (state: any) =>
        state?.questionnaire_data?.questionnaire?.client?.name || ""
    );
    return (
      <div
        className={cn(
          " bg-white  ",
          (page_no === 3 || page_no === 4) && "bg-neutral",
          "md:min-h-screen  md:h-screen h-[100dvh] overflow-hidden  w-full",
          className
        )}
      >
        <div
          className={cn(
            " h-full overflow-hidden flex flex-col justify-between py-3 mx-auto w-full max-w-7xl px-3 md:px-[7rem]"
          )}
        >
          <div className="  md:mb-2  ">
            <WreeHeader
              logo={logo}
              scroll={scroll}
              className={cn(
                "",
                (page_no === 3 || page_no === 4) && "bg-white !shadow-none"
              )}
            />
          </div>

          <div
            onScroll={handleScroll}
            ref={ref}
            className="flex-1   pb-4 md:h-screen  overflow-hidden"
          >
            {children}
          </div>

          <div className="">
            <WreeFooter
              withWorkRightLogo={
                logo !== "" &&
                client_name.toLowerCase().replace(" ", "").trim() !==
                  "workright" &&
                client_name.toLowerCase().replace(" ", "").trim() !== "fragomen"
              }
              className={cn(
                "",
                (page_no === 3 || page_no === 4) && "bg-white !shadow-none"
              )}
            />
          </div>
        </div>
      </div>
    );
  }
);
