import {

  TextInput,
  Title,
  TextArea,
} from "@simplecitizen/gdl-react-ui-components";

import { FieldErrors, UseFormRegister } from "react-hook-form";
import DateInput from "./DateInput";

import SelectInput from "../select-input/SelectInput";

interface RequiredDataProps {
  selectedStatusType: StatusType | null;
  register: UseFormRegister<any>;
  control: any;
  errors: FieldErrors<any>
}

const RequiredData = ({
  selectedStatusType,
  register,
  control,
  errors
}: RequiredDataProps) => {

  return (
    <div>
      <Title className="mt-3 mb-3">Required information</Title>
      <div className="flex flex-col">
        {selectedStatusType?.dataCollectors?.map((dataCollector) => {
          return (
            <div key={dataCollector._id}>
              {(dataCollector._subtype === "select" ||
                dataCollector._subtype === "optionset") && (
                <SelectInput required={dataCollector.required}  errors={errors} control={control} dataCollector={dataCollector} />
              )}
              {dataCollector._subtype === "text" && (
                <TextInput
                 error_message={errors[dataCollector?.key]?.message}
                  labelClassName="font-normal normal-case"
                  label={dataCollector.label}
                  {...register(dataCollector.key,{
                     required: !dataCollector.required ? false :   dataCollector.label ? `${dataCollector.label} is required` : 'This field is required',
                  

                  })}
                />
              )}
              {dataCollector._subtype === "date" && (
                <DateInput required={dataCollector.required} errors={errors} control={control} dataCollector={dataCollector}  />
              )}
              {dataCollector._subtype === "textarea" && (
                <TextArea
                  labelClassName="font-normal normal-case"
                  error_message={errors[dataCollector?.key]?.message}
                  label={dataCollector.label}
                  {...register(dataCollector.key,{
                     required: !dataCollector.required ? false : dataCollector.label ? `${dataCollector.label} is required` : 'This field is required',


                  })}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RequiredData;
