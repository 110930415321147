import {
  DropDown,
  TextInput,
  Title,
  DatePicker,
  InfoBanner,
  Button,
  ErrorBlock,
  Alert,
} from "@simplecitizen/gdl-react-ui-components";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "src/hooks/redux-hooks";
import { countries } from "src/utils/countries";
import { isValidDate, validateInputDate } from "src/utils/utils";
import PopUp from "../pop-up/PopUp";
import TermContentFullScreen from "../terms/TermContentFullScreen";
import { vevoTermsAndConditions } from "./VevoTermsAndConditions";
import { Checkbox } from "../checkbox/Checkbox";
import { format, formatISO } from "date-fns";

interface VevoProps {
  onSubmitVevo: (vevoData: VevoType) => Promise<void>;
  selectedCitizenShip?: "citizen" | "not_citizen" | null;
  isSubmittingVevo: boolean;
  vevoApiError: string | null;
  isVevoDone: boolean;
}

const Vevo = ({
  onSubmitVevo,
  isSubmittingVevo,
  selectedCitizenShip,
  vevoApiError,
  isVevoDone,
}: VevoProps) => {
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );
  const [dateOfBirth, setDateOfBirth] = useState<Date | string>("");
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [dateOfBirthInputValue, setDateOfBirthInputValue] =
    useState<string>("");
  const [dateOfBirthError, setDateOfBirthError] = useState<string>("");
  const [passPortNumberError, setPassPortNumberError] = useState<string>("");
  const [passPortNumber, setPassPortNumber] = useState<string>("");
  const [passPortIssueCountry, setPassPortIssueCountry] = useState<string>("");
  const [isDateValid,setIsDateValid] = useState(false)
  const [passPortIssueCountryError, setPassPortIssueCountryError] =
    useState<string>("");

  const vevo_fields = useMemo(() => {
    return [
      {
        label: "Passport Number",
        name: "passportNumber",
        type: "text",
        error_message: passPortNumberError,
        value: passPortNumber,
        pattern: "[a-zA-Z0-9]/g",

        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          // Allow only alphanumeric characters (a-z, A-Z, 0-9)
          const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");
          setPassPortNumber(alphanumericValue);
        },
      },
      {
        label: "Date of Birth",
        name: "dateOfBirth",
        type: "date",
        error_message: dateOfBirthError,
        value: isValidDate(dateOfBirth?.toString() || "")
          ? (dateOfBirth as Date)
          : new Date(),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setDateOfBirthInputValue(e.target.value);
        },
        onDateChange: (date: Date) => {
          const formattedDate = format(date,'dd/MM/yyyy')
          setDateOfBirth(date);
          setDateOfBirthInputValue(formattedDate);
          setDateOfBirthError("")
          setIsDateValid(false)
        },
      },
      {
        label: "Passport Issue Country",
        name: "passportIssueCountry",
        type: "dropdown",
        options: countries,
        value: passPortIssueCountry,
        hidden: selectedCitizenShip === "citizen",
        error_message: passPortIssueCountryError,
        onSelect: (e: Option) => {
          setPassPortIssueCountry(e.value);
        },
      },
   
    ];
  }, [
    dateOfBirthError,
    passPortIssueCountryError,
    passPortNumberError,
    dateOfBirth,
    passPortIssueCountry,
    passPortNumber,
    selectedCitizenShip,
  ]);


  useEffect(
    ()=>{
      if(dateOfBirthInputValue.trim() === ""){
        return
      }
      const dateValidationError = validateInputDate(dateOfBirthInputValue,true)
   
          if(dateValidationError){
            setDateOfBirthError(dateValidationError || "")
            setIsDateValid(false)
          } else {
            setDateOfBirthError("")
            setIsDateValid(true)
          }

    },[dateOfBirthInputValue]
  )

  const onSubmit = async () => {
  if(!isDateValid){
    return
  }
    setDateOfBirthError("");
    setPassPortNumberError("");
    setPassPortIssueCountryError("");

    let isValid = true;
    if (dateOfBirthInputValue.trim() === "") {
      setDateOfBirthError("Date of Birth is required");
      isValid = false;
    }
    if (!dateOfBirth || dateOfBirth === "") {
      setDateOfBirthError("Date of Birth is required");
      isValid = false;
    }
    if (passPortNumber.trim() === "") {
      setPassPortNumberError("Passport Number is required");
      isValid = false;
    }
    if (passPortIssueCountry.trim() === "") {
      setPassPortIssueCountryError("Passport Issue Country is required");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const vevoData = {
      dateOfBirth:
        typeof dateOfBirth === "string"
          ? dateOfBirth
          : formatISO(dateOfBirth, { representation: 'date' }),
      passportCountryCode: passPortIssueCountry,
      passPortNumber,
    };

    onSubmitVevo(vevoData);
  };
  const [showVevoTerms, setShowVevoTerms] = useState(false);
  return (
    <div className="w-full mt-6">
      {showVevoTerms && (
        <PopUp
          onClose={() => {
            setShowVevoTerms(false);
          }}
        >
          <div>
            <TermContentFullScreen term={vevoTermsAndConditions} />
          </div>
        </PopUp>
      )}
      <Title className="mb-3">
        {selectedCitizenShip === "citizen" ? "Citizenship Check" : "VEVO Check"}
      </Title>
      {isVevoDone ? (
        <div>
          <Alert className="py-2 px-3 flex items-center" type="success">
            <div className="flex gap-2 items-center h-full">
              <div className="">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>

              <div className="flex-1"> VEVO Check Successful</div>
            </div>
          </Alert>
        </div>
      ) : (
        <>
          <div className=" border-b border-b-slate-300 pb-5 mb-8">
            {vevo_fields.map(({ name, type, hidden, ...field }) => (
              <div key={name}>
                {type === "text" && !hidden && (
                  <TextInput
                    required
                    className=""
                    labelClassName="font-normal "
                    {...field}
                  />
                )}
                {type === "dropdown" && !hidden && (
                  <DropDown
                    required
                    isSearchable={true}
                    color={client?.branding?.primaryColor || "#0A71C7"}
                    onChange={(e: any) =>
                      setPassPortIssueCountry(e.target.value)
                    }
                    onClear={() => setPassPortIssueCountry("")}
                    onError={() => setPassPortIssueCountry("")}
                    // initialValue={field.value}
             
                    inputClassName=""
                    label={field.label}
                    name={name}
                    className=""
                    labelClassName="font-normal"
                    options={field.options}
                    onSelect={field.onSelect}
                    error_message={field.error_message}
                  />
                )}
                {type === "date" && !hidden && (
                  <DatePicker
                    required
                    disableFutureDates={true}
                    disablePastDates={false}
                  
                    className=""
                    color={client?.branding?.primaryColor || "#0A71C7"}
                    labelClassName="font-normal"
                    onDateChange={field.onDateChange!}
                    error_message={field.error_message}
                    value={field.value as Date}
                    label={field.label}
                    onChange={field.onChange}
                    placeholder="DD/MM/YYYY"
                    
                  />
                )}
              </div>
            ))}
          </div>
          <InfoBanner
            role="button"
            onClick={() => setIsTermsAgreed((currState) => !currState)}
            className="flex items-center gap-x-2 cursor-pointer"
          >
            <div className="">
              I agree to the{" "}
              <span
                role="button"
                onClick={() => setShowVevoTerms(true)}
                rel="noreferrer"
                style={{ color: client?.branding?.primaryColor || "#0A71C7" }}
                className="font-bold"
              >
                VEVO Terms and Conditions.
              </span>
            </div>
            <Checkbox
              checked={isTermsAgreed}
              onChange={() => {
                // setIsTermsAgreed((currState) => !currState);
              }}
              label={""}
            />
          </InfoBanner>
          {vevoApiError && (
            <ErrorBlock className="mt-6">
              <div className="font-bold">VEVO Check was unsuccessful</div>
              <div>{vevoApiError}</div>
            </ErrorBlock>
          )}

          <Button
            disabled={isSubmittingVevo || !isTermsAgreed}
            className="w-full mt-8"
            type="button"
            onClick={onSubmit}
            color={client?.branding?.primaryColor || "#0A71C7"}
          >
            {isSubmittingVevo
              ? "Submitting Vevo Check...."
              : vevoApiError
                ? "Re-Submit VEVO Check"
                : "Submit VEVO Check"}
          </Button>
        </>
      )}
    </div>
  );
};

export default Vevo;
