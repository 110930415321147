import { DatePicker } from "@simplecitizen/gdl-react-ui-components";
import {  formatISO } from "date-fns";
import React, { useState } from "react";
import { FieldErrors, useController } from "react-hook-form";
import { useAppSelector } from "src/hooks/redux-hooks";
import { validateInputDate } from "src/utils/utils";

interface DateInputProps {
  control: any;
  dataCollector: DataCollector;
  errors: FieldErrors<any>;
  disableFuterDates?:boolean
  required?:boolean
}

const DateInput = ({ control, dataCollector, errors,disableFuterDates = false,required = true }: DateInputProps) => {
  const [dateValue,setDateValue] = useState(new Date())
  const { field } = useController({
    name: dataCollector.key,
    control,
    rules: {
      required: !required ? false : dataCollector.label
        ? `${dataCollector.label} is required`
        : "This field is required",
        validate: (value)=>  validateInputDate(value,disableFuterDates),
    },
  });

  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );

  return (
    <DatePicker
      error_message={errors[dataCollector?.key]?.message}
      // datePickerClassName=""
      // className="-mt-2"
      color={client?.branding?.primaryColor || "#0A71C7"}
      labelClassName="font-normal normal-case"
      value={dateValue}
      onChange = {(e:any)=>{
     
        field.onChange(e.target.value);
      }}
      onDateChange={(date: Date) => {
        const formattedDate =  formatISO(date,{ representation: 'date' })
        setDateValue(date)
         field.onChange(formattedDate);
      }}
      placeholder="DD/MM/YYYY"
      label={dataCollector.label}
    />
  );
};

export default DateInput;
